import Container from "../Container";
import { Modal } from "./Modal";
import backgroundImage from "../../assets/img/hero-bg.webp";
import {
  AddressPurpose,
  BitcoinNetworkType,
  Capability,
  getAddress,
  getCapabilities,
  sendBtcTransaction,
} from "sats-connect";
import { useLocalStorage } from "./useLocalStorage";

import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { replaceMiddleWithDots } from "src/utils";
import CustomTable from "../ClaimTable";
import { adminWallet, backendURL, memPoolURL, payFee } from "src/config";
import { TransactionModal } from "../TransactionModal";
import Footer from "../Footer";
import { toaster } from "src/Toast";
import Loading from "../Loading";
import done from "../../assets/img/done.webp";

import styles from "./index.module.scss";

export interface TableDataItem {
  title: string;
  asset: string;
  count: number;
  pad: number;
  claimable: number;
}

enum statusEnum {
  status1 = "Transaction initiated",
  status2 = "BTC received. Inscribing Transfer.",
  status3 = "Transfer Token initiated.",
  status4 = "Token claim completed.",
}

const ClaimToken = () => {
  let liveRealDataSSE: EventSource;
  const [capabilityState, setCapabilityState] = useState<
    "loading" | "loaded" | "missing" | "cancelled"
  >("loading");
  const [capabilities, setCapabilities] = useState<Set<Capability>>();
  const [unisatInstalled, setUnisatInstalled] = useState(false);
  const [walletType, setWalletType] = useState(0);
  const [paymentAddress, setPaymentAddress] = useLocalStorage("paymentAddress");
  const [ordinalsAddress, setOrdinalsAddress] =
    useLocalStorage("ordinalsAddress");
  const [activeStep, setActiveStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isTransactionLoading, setIsTransactionLoading] = useState(false);
  const selfRef = useRef<{ accounts: string[] }>({
    accounts: [],
  });
  const [openModal, setOpenModal] = useState(false);
  const [gasFee, setGasFee] = useState(200);
  const [isModalOpen, setModalOpen] = useState(false);
  const [showTx, setShowTx] = useState("");
  const [txModelOpen, setTxModelOpen] = useState(false);
  const [txLink, setTxLink] = useState("");
  const [claimableAssets, setClaimableAssets] = useState(0);
  const [claimableTokensAmount, setClaimableTokensAmount] = useState(0);

  const self = selfRef.current;
  const [accounts, setAccounts] = useState<string[]>([]);
  const unisat = (window as any).unisat;

  const [statusMessageIndex, setStatusMessageIndex] = useState(0);

  const [tableData, setTableData] = useState<TableDataItem[]>([
    {
      title: "Satoshi Pass",
      asset: "ogPassCnt",
      count: 0,
      pad: 500000,
      claimable: 0,
    },
    {
      title: "BitMap < 10k",
      asset: "bitmapCntUnder10k",
      count: 0,
      pad: 5000,
      claimable: 0,
    },
    {
      title: "BitMap (10k - 100K)",
      asset: "bitmapCnt10kTo100k",
      count: 0,
      pad: 2500,
      claimable: 0,
    },
    {
      title: "BitMap > 100k",
      asset: "bitmapCntOver100k",
      count: 0,
      pad: 1000,
      claimable: 0,
    },
    {
      title: "Bitcoin Frog",
      asset: "bitFrogCnt",
      count: 0,
      pad: 50000,
      claimable: 0,
    },
    {
      title: "Bitcoin Punk",
      asset: "bitPunkCnt",
      count: 0,
      pad: 10000,
      claimable: 0,
    },
    {
      title: "Node Monke",
      asset: "nodeMonkeCnt",
      count: 0,
      pad: 50000,
      claimable: 0,
    },
    {
      title: "Ordinal Maxi Biz",
      asset: "ombCnt",
      count: 0,
      pad: 50000,
      claimable: 0,
    },
  ]);

  const [network, setNetwork] = useLocalStorage<BitcoinNetworkType>(
    "xversenetwork",
    BitcoinNetworkType.Testnet
  );

  const onWalletDisconnect = () => {
    setPaymentAddress(undefined);
    setOrdinalsAddress(undefined);
    setWalletType(0);
    setActiveStep(1);
  };

  const capabilityMessage =
    capabilityState === "loading"
      ? "Checking capabilities..."
      : capabilityState === "cancelled"
      ? "Capability check cancelled by wallet. Please refresh the page and try again."
      : capabilityState === "missing"
      ? "Could not find an installed Sats Connect capable wallet. Please install a wallet and try again."
      : !capabilities
      ? "Something went wrong with getting capabilities"
      : undefined;

  const runCapabilityCheck = async (walletType: number) => {
    if (walletType === 1) {
      if (capabilityMessage !== undefined) {
        toaster("error", "Install XVerse Wallet");
        return false;
      }
    } else if (walletType === 2) {
      if (unisatInstalled === false) {
        toaster("error", "Install Unisat Wallet");
        return false;
      }
    }
  };

  const handleOpenModal = async () => {
    try {
      const feeRate = await axios.get(
        "https://mempool.space/api/v1/fees/recommended"
      );
      setGasFee(feeRate.data.fastestFee);
    } catch (error) {
      console.error("Error fetching gas fees:", error);
      // Handle the error appropriately.
    }
    setModalOpen(true);
  };

  const checkAvailable = async () => {
    console.log(ordinalsAddress, walletType);
    if (ordinalsAddress !== undefined && walletType !== 0) {
      const res = await axios.post(`${backendURL}/api/check-wallet`, {
        ordinalAddress: ordinalsAddress,
      });
      console.log(res.data.array);
      if (res.data.array.length === 0) {
        toaster("info", "Sorry, but you cannot claim any token.");
      } else {
        setClaimableAssets(
          res.data.ogPassCnt +
            res.data.bitmapCnt10kTo100k +
            res.data.bitmapCntOver100k +
            res.data.bitmapCntUnder10k +
            res.data.bitFrogCnt +
            res.data.bitPunkCnt +
            res.data.nodeMonkeCnt +
            res.data.ombCnt
        );
        const claimableTokens =
          res.data.ogPassCnt * 500000 +
          res.data.bitmapCntUnder10k * 5000 +
          res.data.bitmapCnt10kTo100k * 2500 +
          res.data.bitmapCntOver100k * 1000 +
          res.data.bitFrogCnt * 50000 +
          res.data.bitPunkCnt * 10000 +
          res.data.nodeMonkeCnt * 50000 +
          res.data.ombCnt * 50000;

        setClaimableTokensAmount(claimableTokens ?? 0);

        liveRealDataSSE = new EventSource(
          `${backendURL}/api/get-real-data/${ordinalsAddress}`,
          { withCredentials: true }
        );

        getRealUpdateData(liveRealDataSSE);

        /* toaster(
          "info",
          "You are eligible to claim 1000 tokens because you have BITMAP/FROG/ PUNK"
        ); */

        setTableData(
          tableData.map((data) => ({
            ...data,
            count: res.data[data.asset],
            claimable: res.data[data.asset] * data.pad,
          }))
        );

        setIsLoading(false);
      }
    }
  };
  console.log({ claimableAssets, claimableTokensAmount, tableData });

  const onClaimClick = async () => {
    setIsTransactionLoading(true);
    try {
      // const feeRate = await axios.get(
      //   "https://mempool.space/api/v1/fees/recommended"
      // );
      // console.log(feeRate.data.economyFee);

      // await axios.post(`${backendURL}/api/test`, {
      //   paymentAddress: paymentAddress,
      // });
      //const amount = payFee * claimableAssets  + vbytes * gasFee ;

      // const amount = payFee + vbytes * gasFee;
      const amount = 10000;

      if (walletType === 2) {
        let options = {
          feeRate: 5, // Specify the fee rate here
        };
        const txid = await (window as any).unisat.sendBitcoin(
          adminWallet,
          amount,
          options
        );
        try {
          const res = await axios.post(`${backendURL}/api/claim`, {
            ordinalAddress: ordinalsAddress,
            txID: txid,
          });
          /* setTxModelOpen(true);
          setTxLink(memPoolURL + res.data.id); */
          toaster(
            "success",
            `Request received. Processing your transaction. You will receive tokens shortly.`
          );
          setActiveStep(3);
        } catch (error) {
          console.log(error);
          if (error.response) toaster("error", error.response.data.error);
          else toaster("error", "Claim Failed! Please Try Again Later");
          setIsTransactionLoading(false);
        } finally {
        }
      } else if (walletType === 1) {
        await sendBtcTransaction({
          payload: {
            network: {
              type: network,
            },
            recipients: [
              {
                address: adminWallet,
                amountSats: BigInt(amount),
              },
              // you can add more recipients here
            ],
            senderAddress: paymentAddress!,
          },
          onFinish: async (response: any) => {
            try {
              const res = await axios.post(`${backendURL}/api/claim`, {
                ordinalAddress: ordinalsAddress,
                txID: response,
              });

              /* setTxModelOpen(true);
              setTxLink(memPoolURL + res.data.id); */

              toaster(
                "success",
                `Request received. Processing your transaction. You will receive tokens shortly.`
              );
              setActiveStep(3);
            } catch (error) {
              console.log(error);
              if (error.response) toaster("error", error.response.data.error);
              else toaster("error", "Claim Failed! Please Try Again Later");
            }
          },
          onCancel: () => {
            toaster("error", "Canceled");
            setIsTransactionLoading(false);
          },
        });
      }
      checkAvailable();
    } catch (error) {
      setIsTransactionLoading(false);
      console.log(error);
    }
  };

  const handleConfirm = () => {
    onClaimClick();
    setModalOpen(false);
  };

  const getBasicInfo = async () => {
    const unisat = (window as any).unisat;
    const [address] = await unisat.getAccounts();
    setOrdinalsAddress(address);
  };

  const handleAccountsChanged = (_accounts: string[]) => {
    try {
      if (_accounts[0].length !== 62) {
        toaster("info", "Change Your Wallet Type To Taproot and Connect Again");
        onWalletDisconnect();
        return;
      }
      self.accounts = _accounts;
      if (_accounts.length > 0) {
        setAccounts(_accounts);

        setOrdinalsAddress(_accounts[0]);

        getBasicInfo();
        setWalletType(2);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onConnectClick = async (walletType: number) => {
    let possibility = await runCapabilityCheck(walletType);
    console.log({ possibility, walletType }, ">>>>>>>>possibility");

    if (possibility === false) return;
    if (walletType === 1) {
      await getAddress({
        payload: {
          purposes: [AddressPurpose.Ordinals, AddressPurpose.Payment],
          message: "Connect With BRC20 Claim",
          network: {
            type: network,
          },
        },
        onFinish: (response: any) => {
          const paymentAddressItem = response.addresses.find(
            (address: any) => address.purpose === AddressPurpose.Payment
          );
          setPaymentAddress(paymentAddressItem?.address);

          const ordinalsAddressItem = response.addresses.find(
            (address: any) => address.purpose === AddressPurpose.Ordinals
          );
          setOrdinalsAddress(ordinalsAddressItem?.address);
          setWalletType(1);
          setOpenModal(false);
          setActiveStep(2);
          setIsLoading(true);
        },
        onCancel: () => {},
      });
    } else if (walletType === 2) {
      try {
        const result = await unisat.requestAccounts();
        handleAccountsChanged(result);
        setOpenModal(false);
        setActiveStep(2);
        setIsLoading(true);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const checkXVerseAvailability = async () => {
    let runs = 0;
    const MAX_RUNS = 20;
    setCapabilityState("loading");

    // the wallet's in-page script may not be loaded yet, so we'll try a few times
    while (runs < MAX_RUNS) {
      try {
        await getCapabilities({
          onFinish(response: any) {
            setCapabilities(new Set(response));
            setCapabilityState("loaded");
          },
          onCancel() {
            setCapabilityState("cancelled");
          },
          payload: {
            network: {
              type: network,
            },
          },
        });
        runs++;
      } catch (e) {
        runs++;
        if (runs === MAX_RUNS) {
          setCapabilityState("missing");
        }
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const getRealUpdateData = async (liveRealDataSSE: EventSource) => {
    liveRealDataSSE.addEventListener("message", async (event) => {
      if (
        JSON.parse(event.data).type === "insert" &&
        JSON.parse(event.data).init === true
      ) {
        const transaction = JSON.parse(event.data).data?.at?.(-1);

        if (transaction) {
          if (transaction.status >= 6) {
            return setStatusMessageIndex(3);
          } else {
            setIsTransactionLoading(true);
          }
        }
        if (transaction) {
          if (transaction?.txID && transaction?.status <= 4) setActiveStep(3);
          else setActiveStep(transaction?.status + 1);
          switch (transaction?.status) {
            case 0:
              setStatusMessageIndex(0);
              setShowTx(transaction?.txID);
              break;
            case 5:
              setShowTx(transaction?.inscribeTxID);
              setStatusMessageIndex(2);
              break;
            case 6:
            case 7:
              setStatusMessageIndex(3);
              setShowTx(transaction?.inscribeTxID);
              break;
            default:
              setStatusMessageIndex(1);
              setShowTx("");
          }
        }
      }
      if (
        JSON.parse(event.data).type === "insert" &&
        JSON.parse(event.data).init === false
      ) {
        const transaction = JSON.parse(event.data).data;
        if (
          (Array.isArray(transaction) && transaction?.[0]?.txId) ||
          transaction?.txID
        )
          setActiveStep(3);
        else setActiveStep(transaction?.status + 1);

        switch (transaction?.status) {
          case 0:
            setStatusMessageIndex(0);
            setShowTx(transaction.txID);
            break;
          case 5:
            setStatusMessageIndex(2);
            setShowTx(transaction.inscribeTxID);
            break;
          case 6:
          case 7:
            setStatusMessageIndex(3);
            setShowTx(transaction.inscribeTxID);
            break;
          default:
            setStatusMessageIndex(1);
            setShowTx("");
        }
      }
      if (JSON.parse(event.data).type === "update") {
        const transaction = JSON.parse(event.data).data;
        if (transaction) {
          if (transaction.status >= 6) {
            setStatusMessageIndex(3);
            return setIsTransactionLoading(false);
          } else {
            setIsTransactionLoading(true);
          }
        }

        if (
          transaction?.txID ||
          (transaction?.status <= 4 && transaction?.status >= 0)
        ) {
          setActiveStep(3);
        } else {
          setActiveStep(JSON.parse(event.data).data?.status + 1);
        }
        switch (transaction?.status) {
          case 0:
            setStatusMessageIndex(0);
            setShowTx(transaction.txID);
            break;
          case 5:
            setStatusMessageIndex(2);
            setShowTx(transaction.inscribeTxID);
            break;
          case 6:
          case 7:
            setStatusMessageIndex(3);
            setShowTx(transaction.inscribeTxID);
            break;
          default:
            setStatusMessageIndex(1);
            setShowTx("");
        }
      }
    });
  };

  const checkUnisatAvailability = async () => {
    let unisat = (window as any).unisat;

    for (let i = 1; i < 10 && !unisat; i += 1) {
      await new Promise((resolve) => setTimeout(resolve, 100 * i));
      unisat = (window as any).unisat;
    }

    if (unisat) {
      setUnisatInstalled(true);
    } else if (!unisat) {
      setUnisatInstalled(false);
    }
  };

  useEffect(() => {
    checkAvailable();
    return () => {
      liveRealDataSSE && liveRealDataSSE.close();
    };
  }, [ordinalsAddress, walletType]);

  useEffect(() => {
    checkXVerseAvailability();
  }, [network]);

  useEffect(() => {
    checkUnisatAvailability();
  }, []);

  return (
    <>
      <Container bgImage={backgroundImage}>
        <div className={styles.container}>
          <div className="background-image flex items-center justify-center">
            <div className="w-sm py-4 px-24 mt-24  rounded-sm">
              <div className="pb-4">
                <h1 className="text-white text-4xl text-shadow">
                  Claim OLNG BRC-20 token
                </h1>
                <p className="text-white text-2xl font-bold pt-4 text-shadow mt-6 mb-6 signika">
                  100% allocation to the top ordinal holders.
                </p>
              </div>
              <div className={styles.stepper}>
                <div className={styles.step}>
                  <div
                    className={`${styles.circle} ${
                      activeStep >= 1 ? styles.activeCircle : ""
                    }`}
                  >
                    1
                  </div>
                  <div
                    className={`${styles.name} ${
                      activeStep >= 1 ? styles.activeName : ""
                    }`}
                  >
                    Connect
                  </div>
                  <div
                    className={`${styles.line} ${
                      activeStep > 1 ? styles.activeLine : ""
                    }`}
                  ></div>
                </div>

                <div
                  className={`${styles.step} ${
                    activeStep >= 2 ? styles.active : ""
                  }`}
                >
                  <div
                    className={`${styles.circle} ${
                      activeStep >= 2 ? styles.activeCircle : ""
                    }`}
                  >
                    2
                  </div>
                  <div
                    className={`${styles.name} ${
                      activeStep >= 2 ? styles.activeName : ""
                    }`}
                  >
                    Claim
                  </div>
                  <div
                    className={`${styles.line} ${
                      activeStep > 2 ? styles.activeLine : ""
                    }`}
                  ></div>
                </div>

                <div
                  className={`${styles.step} ${
                    activeStep === 3 ? styles.active : ""
                  }`}
                >
                  <div
                    className={`${styles.circle} ${
                      activeStep >= 3 ? styles.activeCircle : ""
                    }`}
                  >
                    3
                  </div>
                  <div
                    className={`${styles.name} ${
                      activeStep >= 3 ? styles.activeName : ""
                    }`}
                  >
                    Status
                  </div>
                </div>
              </div>

              {isTransactionLoading && <Loading />}

              <div className="flex justify-center mt-12">
                {activeStep === 1 ? (
                  <div>
                    {!openModal && (
                      <button
                        onClick={() => {
                          if (!ordinalsAddress || walletType === 0) {
                            setOpenModal(true);
                          }
                        }}
                        className={styles.disconnect}
                      >
                        Connect
                      </button>
                    )}
                  </div>
                ) : activeStep === 2 ? (
                  <div className="text-white w-full">
                    <div className="w-full flex flex-col gap-6">
                      <div
                        className="w-full flex justify-between items-center gap-6"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <button
                          className={styles.disconnect}
                          onClick={onWalletDisconnect}
                        >
                          Disconnect
                        </button>
                        <div
                          className="w-full flex gap-4 justify-center items-center"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 10,
                          }}
                        >
                          <span className="text-white font-sans font-bold text-xl text-shadow signika">
                            {replaceMiddleWithDots(accounts.toString())}
                          </span>
                          <div style={{ cursor: "pointer" }}>
                            <svg
                              className="h-8 w-8 text-white"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              stroke-width="2"
                              stroke="currentColor"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path stroke="none" d="M0 0h24v24H0z" />
                              <path d="M11 7h-5a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-5" />
                              <line x1="10" y1="14" x2="20" y2="4" />
                              <polyline points="15 4 20 4 20 9" />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div>
                        {isLoading ? (
                          <div>
                            <Loading />
                          </div>
                        ) : (
                          <>
                            {!isTransactionLoading && (
                              <div className="max-w-full mx-auto flex flex-col gap-4">
                                <CustomTable data={tableData} />
                                {/* <p className="text-white text-2xl mt-4 mb-4 signika">
                              Current Gas fee = {gasFee} sats/vb
                            </p> */}
                                <div className="{styles.name}">
                                  Total claimable tokens :{" "}
                                  {claimableTokensAmount} OLNG
                                </div>
                                <button
                                  className={styles.connectWallet}
                                  style={{ margin: "20px 0" }}
                                  onClick={handleOpenModal}
                                >
                                  Claim
                                </button>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {!isTransactionLoading && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          maxWidth: 626,
                          padding: "20px 0",
                        }}
                      >
                        <img src={done} alt="submitted" loading="lazy" />
                      </div>
                    )}
                    <div style={{ color: "#f47115" }}>Status</div>
                    {Object.values(statusEnum).map((status, index) => (
                      <div
                        className={styles.verticalStep}
                        style={{
                          color:
                            index <= statusMessageIndex ? "#f47115" : "white",
                        }}
                      >
                        {index !== 3 && (
                          <div
                            className={styles.verticalLine}
                            style={{
                              background:
                                index <= statusMessageIndex
                                  ? "#f47115"
                                  : "#333",
                            }}
                          ></div>
                        )}
                        <div
                          className={styles.circle}
                          style={{
                            background:
                              index <= statusMessageIndex ? "#f47115" : "none",
                            color: "white",
                          }}
                        >
                          {index + 1}
                        </div>
                        {status}
                      </div>
                    ))}
                  </>
                )}
              </div>
              {showTx !== "" && (
                <div className="text-center" style={{ paddingBottom: 40 }}>
                  TxID :
                  <a
                    href={`${memPoolURL}${showTx}`}
                    target="_blank"
                    className="text-white"
                    style={{ wordBreak: "break-all", color: "#f47115" }}
                    rel="noreferrer"
                  >
                    {"   "}
                    {showTx}
                  </a>
                </div>
              )}
            </div>
          </div>

          {isModalOpen && (
            <div className={styles.confirmModalWrapper}>
              <div className={styles.confirmModal}>
                <p>Amount to Claim: {claimableTokensAmount} OLNG</p>
                <p>Gas Fees: {gasFee ? `${gasFee} sats/vB` : "Loading..."}</p>
                {/* <p>Dev Fees: {payFee * claimableAssets} sats</p> */}
                <p>Community support Fees: {payFee} sats</p>

                {/* <i>(10000 sats per ordinal claimed)</i> */}

                <div className={styles.confirmButtons}>
                  <button
                    onClick={handleConfirm}
                    className={styles.connectWallet}
                  >
                    Confirm
                  </button>
                  <button onClick={handleCloseModal} className={styles.cancel}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}
          {openModal && (
            <Modal
              isOpen={openModal}
              toggleModal={setOpenModal}
              walletConnect={onConnectClick}
            />
          )}
          <TransactionModal
            isOpen={txModelOpen}
            toggleModal={setTxModelOpen}
            link={txLink}
          />
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default ClaimToken;
