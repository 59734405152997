import React from "react";

import styles from "./index.module.scss";

interface ContainerProps {
  children: React.ReactNode;
  bgImage?: string;
  bgColor?: string;
}

const Container: React.FC<ContainerProps> = ({
  children,
  bgImage,
  bgColor,
}) => {
  return (
    <div className={styles.containerWrap} style={{ backgroundColor: bgColor }}>
      <div className={styles.bigContainer}>
        {bgImage && (
          <div className={styles.imgWrap}>
            <img
              src={bgImage}
              className={styles.bgImage}
              loading="lazy"
              alt="background-img"
            />
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

export default Container;
