import React, { useEffect, useState } from "react";
import acces from "../../assets/img/access.webp";
import government from "../../assets/img/government.webp";
import reward from "../../assets/img/reward.webp";

import styles from "./index.module.scss";

/* const slides = [
  {
    id: 1,
    title: "Exclusive Access",
    description:
      "Gain early and exclusive access to new blockchain projects and token sales on OGLONG.",
    imageUrl: acces,
  },
  {
    id: 2,
    title: "Governance Rights",
    description:
      "Participate in governance decisions and influence the future direction of the OGLONG platform.",
    imageUrl: government,
  },
  {
    id: 3,
    title: "Staking Rewards",
    description:
      "Earn rewards by staking tokens, enhancing your benefits and influence within the OGLONG ecosystem.",
    imageUrl: reward,
  },
  {
    id: 4,
    title: "Reduced Fees",
    description:
      "Enjoy reduced fees on the OGLONG platform, making your transactions more cost-effective.",
    imageUrl: reward,
  },
  {
    id: 5,
    title: "Exclusive Airdrops",
    description:
      "OLNG holders are eligible for exclusive airdrops of new tokens or projects.",
    imageUrl: reward,
  }, 
  {
    id: 6,
    title: "Exclusive Access",
    description:
      "Gain early and exclusive access to new blockchain projects and token sales on OGLONG.",
    imageUrl: acces,
  },
  {
    id: 7,
    title: "Governance Rights",
    description:
      "Participate in governance decisions and influence the future direction of the OGLONG platform.",
    imageUrl: government,
  },
  {
    id: 8,
    title: "Staking Rewards",
    description:
      "Earn rewards by staking tokens, enhancing your benefits and influence within the OGLONG ecosystem.",
    imageUrl: reward,
  },
  {
    id: 9,
    title: "Reduced Fees",
    description:
      "Enjoy reduced fees on the OGLONG platform, making your transactions more cost-effective.",
    imageUrl: reward,
  },
   {
    id: 10,
    title: "Exclusive Airdrops",
    description:
      "OLNG holders are eligible for exclusive airdrops of new tokens or projects.",
    imageUrl: reward,
  }, 
]; */

const slides = [
  {
    id: 1,
    title: "Efficient UTXO Management",
    description:
      "Optimized Bitcoin transactions with our advanced UTXO management system for faster, cost-effective transfers.",
    imageUrl: acces,
  },
  {
    id: 2,
    title: "Tier-Based Allocation",
    description:
      "Dynamic tiered system for fair and strategic token distribution, ensuring equitable access for all users.",
    imageUrl: government,
  },
  {
    id: 3,
    title: "Seamless Ordinal Integration",
    description:
      "Seamlessly connects with Bitcoin Ordinals for an intuitive and straightforward token claiming experience.",
    imageUrl: reward,
  },
  {
    id: 4,
    title: "BRC20 Project Launches",
    description:
      "Launching BRC20 projects on our strategic platform, making a significant impact in the Bitcoin ecosystem.",
    imageUrl: reward,
  },
  {
    id: 5,
    title: "Community-Driven Governance",
    description:
      "Shape the platform's future through collective decision-making and consensus-driven governance.",
    imageUrl: reward,
  },
  {
    id: 6,
    title: "Efficient UTXO Management",
    description:
      "Optimized Bitcoin transactions with our advanced UTXO management system for faster, cost-effective transfers.",
    imageUrl: acces,
  },
  {
    id: 7,
    title: "Tier-Based Allocation",
    description:
      "Dynamic tiered system for fair and strategic token distribution, ensuring equitable access for all users.",
    imageUrl: government,
  },
  {
    id: 8,
    title: "Seamless Ordinal Integration",
    description:
      "Seamlessly connects with Bitcoin Ordinals for an intuitive and straightforward token claiming experience.",
    imageUrl: reward,
  },
  {
    id: 9,
    title: "BRC20 Project Launches",
    description:
      "Launching BRC20 projects on our strategic platform, making a significant impact in the Bitcoin ecosystem.",
    imageUrl: reward,
  },
  {
    id: 10,
    title: "Community-Driven Governance",
    description:
      "Shape the platform's future through collective decision-making and consensus-driven governance.",
    imageUrl: reward,
  },
];
export default function Slider() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (isMobile) {
    return (
      <div className={styles.mobileContainer}>
        {slides.slice(0, 5).map((item) => (
          <div key={item.id} className={styles.mobileSlide}>
            <img src={item.imageUrl} alt={item.title} loading="lazy" />
            <div className={styles.title}>{item.title}</div>
            <div className={styles.description}>{item.description}</div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className={styles.container1}>
      <div className={styles.slider1}>
        <div className={styles.slideTrack}>
          {slides.map((item) => (
            <div key={item.id} className={styles.swiperSlide}>
              <img src={item.imageUrl} alt={item.title} loading="lazy" />
              <div className={styles.title}>{item.title}</div>
              <div className={styles.description}>{item.description}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
