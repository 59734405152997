export interface FormData {
  walletAddress: string;
  twitterHandle: string;
  telegramUsername: string;
  thoughts: string;
  [key: string]: string;
}

//const API_BASE_URL = "http://localhost:5000/api";
//const API_BASE_URL = "https://ee194949c66214131fb1d165c2f2010c.serveo.net/api";
const API_BASE_URL = "https://api.btcpad.co/api";


export const submitWhitelistForm = async (formData: FormData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/whitelist`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      throw new Error("Failed to submit form");
    }

    console.log("Form submitted successfully");
  } catch (error) {
    console.error("Error submitting form:", error.message);
    throw error;
  }
};
