import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LazyLoad from "react-lazy-load";
import Container from "../Container";
import Popup from "../Popup";
import Slider from "../Slider";
import { nftData } from "./nftData";
import backgroundImage from "../../assets/img/hero-bg.webp";
import backgroundImageMobile from "../../assets/img/hero-bg-mobile.webp";
import circle from "../../assets/img/circle.webm";
import star from "../../assets/img/star.webm";
import roundStar from "../../assets/img/round-star.webm";
import rocket from "../../assets/img/rocket.webp";
import bitcoin from "../../assets/img/bitcoin.png";
import link from "../../assets/img/link-orange.png";

import styles from "./index.module.scss";

const Hero = () => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const popupShownBefore = sessionStorage.getItem("popupShown");

    if (!popupShownBefore) {
      const timeout = setTimeout(() => {
        setShowPopup(true);
        sessionStorage.setItem("popupShown", "true");
      }, 5000);

      return () => clearTimeout(timeout);
    }
  }, []);

  return (
    <Container bgImage={isMobile ? backgroundImageMobile : backgroundImage}>
      <div className={styles.container}>
        {showPopup && <Popup />}
        <div className={styles.textContainer}>
          <LazyLoad offset={100}>
            <video autoPlay loop muted className={styles.halfCircle}>
              <source src={circle} type="video/mp4" />
            </video>
          </LazyLoad>

          <img
            src={bitcoin}
            className={styles.bitcoin}
            alt="bitcoin"
            loading="lazy"
          />
          <div className={styles.title}>
            <div>
              1<span>st</span> Community owned
            </div>
            <div style={{ color: "white" }}> BRC-20 Launchpad</div>
          </div>
          <div className={styles.description}>
            {/*<div>
              You can claim Tokens if you hold one of these collections. Token
            </div>
            <div>Launch coming soon ...</div>*/}
          </div>
          <div className={styles.buttonWrapper}>
            <button onClick={() => navigate("claim-token")} disabled>
              CLAIM TOKEN (coming soon)
            </button>
          </div>
          <div className={styles.nftTitle}>100% tokens for the community.</div>
          {/* <div className={styles.description1}>
            <div>Exclusive for these asset hodlers :</div>
          </div> */}
          <div className={styles.nftContainer}>
            {nftData.map((nft) => (
              <div className={styles.nft} key={nft.title}>
                <img src={nft.imageUrl} alt="nft" loading="lazy" />
                <div>{nft.title}</div>
              </div>
            ))}
          </div>
          <LazyLoad offset={100}>
            <video autoPlay loop muted className={styles.circle}>
              <source src={circle} type="video/mp4" />
            </video>
          </LazyLoad>
          <div className={styles.whitelistSection}>
            <img src={link} alt="link" width={20} height={20} loading="lazy" />
            <div
              className={styles.whitelistButton}
              onClick={() => navigate("whitelist")}
            >
              whitelist for Satoshi Pass
            </div>
          </div>
        </div>
        <div className={styles.imgWrap}>
          <div className={styles.rocket}>
            <img src={rocket} alt="rocket" loading="lazy" />
          </div>
          {/* <div className={styles.star}>
            <img src={star} alt="star" />
          </div> */}
          <div className={styles.star}>
            <LazyLoad offset={100}>
              <video autoPlay loop muted>
                <source src={star} type="video/webm" />
              </video>
            </LazyLoad>
          </div>
          <div className={styles.smallStar}>
            <LazyLoad offset={100}>
              <video autoPlay loop muted>
                <source src={roundStar} type="video/webm" />
              </video>
            </LazyLoad>
          </div>
        </div>
      </div>
      <Slider />
    </Container>
  );
};

export default Hero;
