import { useEffect, useState } from "react";
import telegram from "../../assets/img/telegram.png";
import twitter from "../../assets/img/twitter.png";
import discord from "../../assets/img/discord.png";
import logo from "../../assets/img/logo.webp";

import styles from "./index.module.scss";

const Header = () => {
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const scrollThreshold = 50;

      if (scrollY > scrollThreshold) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div
        className={`${styles.container} ${scrolling ? styles.scrolled : ""}`}
      >
        <div className={styles.wrapper}>
          <div className={styles.logo}>
            <a href="/" onClick={scrollToTop}>
              <img src={logo} alt="logo" loading="lazy" />
            </a>
          </div>
          <div className={styles.socialIcons}>
            <a href="https://t.me/oglong_official" target="blank">
              <img src={telegram} alt="telegram" loading="lazy" />
            </a>
            <a href="https://twitter.com/oglong_official" target="blank">
              <img src={twitter} alt="twitter" loading="lazy" />
            </a>
            <a href="https://discord.com/invite/wGNAtQAW2V" target="blank">
              <img src={discord} alt="discord" loading="lazy" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
