import { useNavigate } from "react-router-dom";
import logo from "../../assets/img/og-pass-logo.webp";
import link from "../../assets/img/link-orange.png";
import star from "../../assets/img/star-logo.png";
import agreement from "../../assets/img/agreement.png";
import medal from "../../assets/img/medal.png";

import styles from "./index.module.scss";

const OgPassBenefits = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <h2>Satoshi Pass Benefits</h2>
      <div className={styles.logoContainer}>
        <img src={logo} alt="logo" className={styles.logo} loading="lazy" />
        <div className={styles.description}>
          There is a limited supply of only 420 OGLONG Satoshi Pass
        </div>
        <div className={styles.whitelistSection}>
          <img src={link} alt="link" loading="lazy" />
          <div
            className={styles.whitelistButton}
            onClick={() => navigate("whitelist")}
          >
            whitelist for Satoshi pass
          </div>
        </div>
      </div>
      <div className={styles.benefitsContainer}>
        <div className={styles.benefit}>
          <img src={star} alt="star" loading="lazy" />
          <div style={{ width: 246 }}>An airdrop of 500,000 OLNG tokens</div>
        </div>
        <div className={styles.benefit}>
          <img src={agreement} alt="agreement" loading="lazy" />
          <div style={{ width: 336 }}>
            Access to the highest tier of allocation for projects launched on
            OGLONG
          </div>
        </div>
        <div className={styles.benefit}>
          <img src={medal} alt="medal" loading="lazy" />
          <div style={{ width: 246 }}>
            Voting rights to decide the future plans for OGLONG
          </div>
        </div>
      </div>
    </div>
  );
};

export default OgPassBenefits;
