import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import TextEditor from "../TextEditor";
import { FormData, submitWhitelistForm } from "src/api";
import logo from "../../assets/img/og-pass-logo.webp";
import done from "../../assets/img/done.webp";
import close from "../../assets/img/close-white.png";
import back from "../../assets/img/back.png";

import styles from "./index.module.scss";

interface FieldConfig {
  name: string;
  label: string;
  errorMessage: string;
  regex?: RegExp;
}

const fields: {
  title: string;
  description?: JSX.Element;
  fields: FieldConfig[];
}[] = [
  /* {
    title: "Name",
    fields: [
      { name: "firstName", label: "First Name" },
      { name: "lastName", label: "Last Name" },
    ],
  }, */
  {
    title: "Wallet Address",
    description: (
      <div className={styles.description}>
        Give your BRC20 wallet address<span>*</span>
        <br />
        We support <b>Unisat</b> & <b>Xverse</b> for now.
      </div>
    ),
    fields: [
      {
        name: "walletAddress",
        label: "Wallet Address",
        errorMessage: "Wallet address is not valid",
        regex: /^.{8,}$/,
      },
    ],
  },
  {
    title: "X Handle",
    description: (
      <div className={styles.description}>
        Follow us on X
        <a href="https://twitter.com/oglong_official">
          (https://twitter.com/oglong_official)
        </a>{" "}
        and
        <br />
        give your X handle.<span>*</span>
      </div>
    ),
    fields: [
      {
        name: "twitterHandle",
        label: "X (twitter) Handle",
        errorMessage: "X handle is required",
        // regex: /^@[A-Za-z0-9_]{1,15}$/,
      },
    ],
  },
  {
    title: "Telegram",
    description: (
      <div className={styles.description}>
        Join our Telegram group{" "}
        <a href="https://t.me/oglong_official">
          (https://t.me/oglong_official)
        </a>{" "}
        &
        <br />
        give your Telegram username.<span>*</span>
      </div>
    ),
    fields: [
      {
        name: "telegramUsername",
        label: "Your Telegram Username",
        errorMessage: "Telegram username is required",
        // regex: /^@[A-Za-z0-9_]{4,}$/,
      },
    ],
  },
  /* {
    title: "Discord",
    description: (
      <div className={styles.description}>
        Join Our Discord community{" "}
        <a href="(https://discord.gg/pHhTYfFM">
          (https://discord.gg/
          <br />
          pHhTYfFM)
        </a>{" "}
        & give your Discord username.<span>*</span>
      </div>
    ),
    fields: [{ name: "discordUsername", label: "Your Discord Username" }],
  }, */
  {
    title: "How will you Contribute?",
    description: (
      <div className={styles.description}>
        Explain how you will bring value to the OGLONG Community.
      </div>
    ),
    fields: [
      {
        name: "thoughts",
        label: "Write your thoughts here...",
        errorMessage: "Your thoughts are required",
      },
    ],
  },
];

const Whitelist = () => {
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState<FormData>({
    walletAddress: "",
    twitterHandle: "",
    telegramUsername: "",
    thoughts: "",
  });
  const [touchedFields, setTouchedFields] = useState<{
    [key: string]: boolean;
  }>({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isValidFields, setIsValidFields] = useState<boolean[]>(
    fields.map(() => false)
  );
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/");
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setTouchedFields((prevTouchedFields) => ({
      ...prevTouchedFields,
      [name]: true,
    }));

    const isEmpty = value.trim() === "";

    const fieldConfig = fields[step].fields.find(
      (field) => field.name === name
    );
    let isValid = true;
    if (fieldConfig && fieldConfig.regex) {
      isValid = fieldConfig.regex.test(value);
    }

    isValid = isValid && !isEmpty;

    setIsValidFields((prevState) => {
      const newState = [...prevState];
      newState[step] = isValid;
      return newState;
    });
  };

  const handleEditorChange = (content: string) => {
    setFormData((prevData) => ({
      ...prevData,
      thoughts: content,
    }));
  };

  const handleNext = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handlePrevious = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = async () => {
    try {
      await submitWhitelistForm(formData);
      console.log("Form submitted successfully");
      setIsSubmitted(true);
    } catch (error) {
      console.log("Error happened");
    }
  };

  const isFieldEmpty = (fieldName: string) => {
    return !formData[fieldName].trim();
  };

  const shouldShowErrorMessage = (fieldName: string) => {
    return touchedFields[fieldName] && isFieldEmpty(fieldName);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.overlay}>
          {!isSubmitted && (
            <div className={styles.backButtonContainer}>
              <div className={styles.back} onClick={handleNavigate}>
                <img src={back} alt="back" loading="lazy" />
                Back
              </div>
            </div>
          )}
          <div className={styles.modalContainer}>
            <div className={styles.modalWrapper}>
              <div className={styles.logo}>
                <img src={logo} alt="logo" loading="lazy" />
              </div>
              <div className={styles.logoTitle}>
                Join the OGLONG whitelist for early access to groundbreaking
                BRC-20 token projects, shaping the future of decentralized
                finance with Bitcoin's power.
              </div>
              <div
                className={styles.modal}
                onClick={(e) => e.stopPropagation()}
              >
                {isSubmitted ? (
                  <>
                    <img
                      src={close}
                      alt="close"
                      className={styles.close}
                      onClick={handleNavigate}
                      loading="lazy"
                    />
                    <div className={styles.confirmModal}>
                      <img src={done} alt="submitted" loading="lazy" />
                      <div className={styles.title}>Form is submitted!</div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={styles.title}>{fields[step].title}</div>
                    {fields[step].description && (
                      <div className={styles.description}>
                        {fields[step].description}
                      </div>
                    )}
                    <div className={styles.inputContainer}>
                      {fields[step].fields.map((field, index) => (
                        <div className={styles.inputWrapper} key={index}>
                          {field.name === "thoughts" ? (
                            <TextEditor onChange={handleEditorChange} />
                          ) : (
                            <input
                              type="text"
                              name={field.name}
                              value={formData[field.name]}
                              onChange={handleInputChange}
                              placeholder={field.label}
                            />
                          )}
                          {shouldShowErrorMessage(field.name) && (
                            <p>{field.errorMessage}</p>
                          )}
                        </div>
                      ))}
                    </div>
                    <div className={styles.navigationButtons}>
                      {step > 0 && (
                        <button onClick={handlePrevious}>Previous</button>
                      )}
                      {step < fields.length - 1 && (
                        <button
                          onClick={handleNext}
                          disabled={!isValidFields[step]}
                        >
                          Next
                        </button>
                      )}
                      {step === fields.length - 1 && (
                        <button onClick={handleSubmit}>Submit</button>
                      )}
                    </div>
                  </>
                )}
              </div>
              {isSubmitted || (
                <div className={styles.progressBar}>
                  {fields.map((field, index) => (
                    <div className={styles.stepWrapper} key={index}>
                      <div
                        className={`${styles.progressStep} ${
                          index === step ? styles.active : ""
                        }`}
                      ></div>
                      <div className={styles.step}>
                        {index === step && `step ${step + 1} of 4`}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Whitelist;
