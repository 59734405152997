import { useEffect, useState } from "react";
import LazyLoad from "react-lazy-load";
import Container from "../Container";
import DoughnutChart from "../Chart";
import Assets from "../Assets";
import HowToClaim from "../HowToClaim";
import OgPassBenefits from "../OgPassBenefits";
import Footer from "../Footer";
import { data, IData } from "./data";
import backgroundImage from "../../assets/img/landing-page-bg.webp";
import backgroundImageMobile from "../../assets/img/landing-page-bg-mobile.webp";
import roundStar from "../../assets/img/round-star.png";
import sqareStar from "../../assets/img/sqare-star.webm";

import styles from "./index.module.scss";

const Card: React.FC<{ data: IData; id: number }> = ({ data, id }) => {
  return (
    <div
      className={styles.card}
      style={{ flexDirection: id % 2 ? "row-reverse" : "row" }}
    >
      <div className={styles.cardImageWrapper}>
        {data.image && (
          <img
            src={data.image.url}
            alt={data.title}
            className={styles[data.image.className]}
            loading="lazy"
          />
        )}

        {data.video && (
          <div className={styles[data.video.className]}>
            <LazyLoad offset={100}>
              <video autoPlay loop muted>
                <source src={data.video.url} type="video/webm" />
              </video>
            </LazyLoad>
          </div>
        )}

        {data.gifs?.map((gif, index) => (
          <img
            key={index}
            src={gif.url}
            alt="gif"
            className={styles[gif.className]}
            loading="lazy"
          />
        ))}

        {data.videos?.map((video, index) => (
          <div className={styles[video.className]} key={index}>
            <LazyLoad offset={100}>
              <video autoPlay loop muted>
                <source src={video.url} type="video/webm" />
              </video>
            </LazyLoad>
          </div>
        ))}
      </div>
      <div>
        <h2>{data.title}</h2>
        {typeof data.description === "string" ? (
          <p>{data.description}</p>
        ) : (
          <ul>
            {data.description.map((desc, index) => (
              <li key={index}>{desc}</li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

const Landing = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Container bgImage={isMobile ? backgroundImageMobile : backgroundImage}>
        <div className={styles.chartTitle}>OLNG BRC-20 Allocation</div>

        <div className={styles.chartWrapper}>
          <div className={styles.chartContainer}>
            <div className={styles.supply}>
              Token Supply = 2,412,650,000 OLNG
            </div>
            <div className={styles.community}>
              100% tokens allocated for the following communities: <br />
              No Private VCs.
            </div>
            <DoughnutChart />
          </div>
          <Assets />
        </div>
        <HowToClaim />
        <OgPassBenefits />

        <div className={styles.headTitle}>
          <img
            src={roundStar}
            className={styles.roundStar}
            alt="round star"
            loading="lazy"
          />
          <div className={styles.sqareStar}>
            <LazyLoad offset={100}>
              <video autoPlay loop muted>
                <source src={sqareStar} type="video/webm" />
              </video>
            </LazyLoad>
          </div>
          <div>2024 belongs to BTC & BRC-20 tokens</div>
          <p>
            In 2024, the synergy between Bitcoin (BTC) and the emerging BRC-20
            standard is poised to reshape the landscape of digital assets. The
            BRC-20 protocol enhances Bitcoin's functionality, enabling the
            creation of tokens directly on the Bitcoin blockchain.
          </p>
        </div>

        <div className={styles.cardsContainer}>
          {data.map((card, index) => (
            <Card data={card} id={index} key={index} />
          ))}
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default Landing;
