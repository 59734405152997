import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import close from "../../assets/img/close-white.png";
import logo from "../../assets/img/og-pass-logo.webp";
import link from "../../assets/img/link-orange.png";

import styles from "./index.module.scss";

const Popup = () => {
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOutsideClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if ((e.target as HTMLElement).classList.contains(styles.overlay)) {
      setIsOpen(false);
    }
  };

  return (
    <>
      {isOpen && (
        <div className={styles.overlay} onClick={handleOutsideClick}>
          <div className={styles.popup}>
            <img
              src={close}
              alt="close"
              className={styles.closeButton}
              onClick={handleClose}
              loading="lazy"
            />
            <img src={logo} alt="logo" className={styles.logo} loading="lazy" />
            <h2>Introducing OGLONG Satoshi PASS</h2>
            <ul className={styles.bulletList}>
              <li>Only 420 passes</li>
              <li>Airdrop of 500,000 OLNG tokens</li>
              <li>
                Access to topmost tier of allocation for future projects on
                OGLONG
              </li>
            </ul>
            <div className={styles.whitelistSection}>
              <img src={link} alt="link" loading="lazy" />
              <div
                className={styles.whitelistButton}
                onClick={() => navigate("whitelist")}
              >
                whitelist for Satoshi Pass
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Popup;
