import { Route, Routes } from "react-router-dom";
import ClaimToken from "src/components/ClaimToken";
import Whitelist from "src/components/WhitelistModal";
import Home from "src/pages/Home";

function AppRouter() {
  return (
    <div className="d-flex flex-column h-100 primary-bg">
      <div className="app-bg flex-grow-1">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="whitelist" element={<Whitelist />} />
          <Route path="claim-token" element={<ClaimToken />} />
        </Routes>
      </div>
    </div>
  );
}

export default AppRouter;
