import styles from "./index.module.scss";

export const data = [
  { asset: "Ordinal", asset4btp: "# of tokens/ ordinal" },
  { asset: "Satoshi Pass", asset4btp: "500,000 OLNG" },
  { asset: "Ordinal Maxi Biz", asset4btp: "60,000 OLNG" },
  { asset: "Quantum Cats", asset4btp: "50,000 OLNG" },
  { asset: "Node Monke", asset4btp: "40,000 OLNG" },
  { asset: "Bitcoin Frog", asset4btp: "40,000 OLNG" },
  { asset: "Bitcoin Punk", asset4btp: "10,000 OLNG" },
  { asset: "BitMap < 10k", asset4btp: "5,000 OLNG" },
  { asset: "BitMap (10k-100K)", asset4btp: "2,500 OLNG" },
  { asset: "BitMap > 100k", asset4btp: "1,000 OLNG" },
];

const Assets = () => {
  return (
    <div className={styles.assetsWrapper}>
      {data.map((a, index) => (
        <div
          className={` ${styles.asset} ${!index ? styles.firstAsset : ""}`}
          key={index}
        >
          <div>{a.asset}</div>
          <div className={`${index ? styles.numberAsset : ""}`}>
            {a.asset4btp}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Assets;
