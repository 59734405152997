import React, { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";

import boldIcon from "../../assets/img/bold.png";
import italicIcon from "../../assets/img/italic.png";
import underlineIcon from "../../assets/img/underline.png";
import listIcon from "../../assets/img/unordered-list.png";
import linkIcon from "../../assets/img/link.png";
import imageIcon from "../../assets/img/image.png";
import emojiIcon from "../../assets/img/emoji.png";

import styles from "./index.module.scss";

interface TextEditorProps {
  onChange: (content: string) => void;
}

const TextEditor: React.FC<TextEditorProps> = ({ onChange }) => {
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  );

  const onEditorStateChange = (editorState: EditorState) => {
    onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    setEditorState(editorState);
  };

  return (
    <div className={styles.editorWrapper}>
      <Editor
        placeholder="Write your thoughts here..."
        editorState={editorState}
        toolbar={{
          options: ["inline", "list", "link", "emoji", "image"],
          inline: {
            options: ["bold", "italic", "underline"],
            bold: { icon: boldIcon },
            italic: { icon: italicIcon },
            underline: { icon: underlineIcon },
          },
          list: {
            unordered: {
              icon: listIcon,
            },
            className: styles.customListToolbar,
            dropdownClassName: styles.customListDropdown,
            options: ["unordered"],
          },
          link: {
            link: {
              icon: linkIcon,
            },
            className: styles.customLinkToolbar,
            options: ["link"],
          },
          image: { icon: imageIcon },
          emoji: { icon: emojiIcon },
        }}
        toolbarClassName={styles.toolbarClassName}
        wrapperClassName={styles.wrapperClassName}
        editorClassName={styles.editorClassName}
        onEditorStateChange={onEditorStateChange}
      />
    </div>
  );
};

export default TextEditor;
