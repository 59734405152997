import Container from "../Container";
import logo from "../../assets/img/logo.webp";
import telegram from "../../assets/img/telegram.png";
import twitter from "../../assets/img/twitter.png";
import discord from "../../assets/img/discord.png";

import styles from "./index.module.scss";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className={styles.wrapper}>
      <Container bgColor="black">
        <div className={styles.container}>
          <div className={styles.topSection}>
            <a href="/" onClick={scrollToTop} className={styles.logo}>
              <img src={logo} alt="logo" />
            </a>
            <div className={styles.socialIcons}>
              <a href="https://t.me/oglong_official" target="blank">
                <img src={telegram} alt="telegram" loading="lazy" />
              </a>
              <a href="https://twitter.com/oglong_official" target="blank">
                <img src={twitter} alt="twitter" loading="lazy" />
              </a>
              <a href="https://discord.com/invite/wGNAtQAW2V" target="blank">
                <img src={discord} alt="discord" loading="lazy" />
              </a>
            </div>
          </div>
          <p>Copyright OGLONG © (since) 2009 . All Rights Reserved.</p>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
