import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import eligibility from "../../assets/img/eligibility.webp";
import claim from "../../assets/img/claim.webp";
import transaction from "../../assets/img/transaction.webp";
import step1 from "../../assets/img/claim-step1.webp";
import step2 from "../../assets/img/claim-step2.webp";
import step3 from "../../assets/img/claim-step3.webp";
import background from "../../assets/img/claim-bg.png";

import styles from "./index.module.scss";

const data = [
  {
    logoImg: eligibility,
    description:
      "Connect your Bitcoin wallet to the OGLONG platform to authenticate and establish the presence of the eligible ordinals.",
    stepImg: step1,
  },
  {
    logoImg: claim,
    description:
      "Once verified, users can claim OLNG tokens directly at the OGLONG platform. # of tokens claimable depends on the type and number of ordinals held.",
    stepImg: step2,
  },
  {
    logoImg: transaction,
    description:
      "Users need to confirm a transaction to cover the network fee, after which the OLNG tokens will be inscribed and transferred to their wallet.",
    stepImg: step3,
  },
];

const titles = [
  {
    number: "01",
    text: "Check Eligibility",
  },
  {
    number: "02",
    text: "Claim",
  },
  {
    number: "03",
    text: "Transaction",
  },
];
const HowToClaim = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <></>,
    nextArrow: <></>,
  };

  return (
    <div className={styles.wrapper}>
      <h2>How to claim OLNG</h2>

      <div className={styles.mobile}>
        <div className={styles.container}>
          <div className={styles.cardWrapper}>
            <Slider {...sliderSettings} className={styles.slider}>
              {data.map((item, index) => (
                <div className={styles.slide} key={index}>
                  <div className={styles.road}>
                    <div className={styles.titleContainer}>
                      <div className={styles.title}>
                        <div className={styles.titleNumber}>
                          {titles[index].number}
                        </div>
                        <div className={styles.titleText}>
                          {titles[index].text}
                        </div>
                      </div>
                    </div>
                    <div className={styles.bulletsContainer}>
                      <div className={styles.bulletWrapper}>
                        <div className={styles.bullet}></div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.card}>
                    <div className={styles.imgWrapper}>
                      <img
                        src={background}
                        alt="background"
                        className={styles.yellowBg}
                        loading="lazy"
                      />
                      <img
                        src={item.logoImg}
                        className={styles.logo}
                        alt="logo"
                        loading="lazy"
                      />
                    </div>
                    <div className={styles.descriptionContainer}>
                      <div className={styles.description}>
                        {item.description}
                      </div>
                      <div className={styles.stepImgContainer}>
                        <img src={item.stepImg} alt="step" loading="lazy" />
                        <div>{index + 1}</div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>

      <div className={styles.web}>
        <div className={styles.container}>
          <div className={styles.road}>
            <div className={styles.titleContainer}>
              {titles.map((title, index) => (
                <div className={styles.title} key={index}>
                  <div className={styles.titleNumber}>{title.number}</div>
                  <div className={styles.titleText}>{title.text}</div>
                </div>
              ))}
            </div>
            <div className={styles.bulletsContainer}>
              <div className={styles.bulletWrapper}>
                <div className={styles.bullet}></div>
                <div className={styles.bullet}></div>
                <div className={styles.bullet}></div>
              </div>
            </div>
          </div>
          <div className={styles.cardWrapper}>
            {data.map((item, index) => (
              <div className={styles.card} key={index}>
                <div className={styles.imgWrapper}>
                  <img
                    src={background}
                    alt="background"
                    className={styles.yellowBg}
                    loading="lazy"
                  />
                  <img src={item.logoImg} className={styles.logo} alt="logo" />
                </div>
                <div className={styles.descriptionContainer}>
                  <div className={styles.description}>{item.description}</div>
                  <div className={styles.stepImgContainer}>
                    <img src={item.stepImg} alt="step" loading="lazy" />
                    <div>{index + 1}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowToClaim;
