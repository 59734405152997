
import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import styles from "./index.module.scss";

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart: React.FC = () => {
  const data = [210000000, 126000000, 166650000, 400000000, 400000000, 100000000, 1010000000];
  const labels = ["Satoshi Pass", "Ordinal Maxi Biz", "Quantum Cats", "NodeMonke", "Bitcoin Frog","Bitcoin Punk", "BITMAP" ]; // Example asset names
  const total = data.reduce((acc, val) => acc + val, 0);
  const percentages = data.map(
    (value) => ((value / total) * 100).toFixed(2) + "%"
  );

  const chartData = {
    labels: labels.map((label, index) => `${label} (${percentages[index]})`), // Combine asset names with percentages
    datasets: [
      {
        data: data,
        backgroundColor: [
          "#E63946", // Bright Red
          "#F1FAEE", // Very Light Blue, almost White for contrast
          "#A8DADC", // Light Blue
          "#457B9D", // Medium Blue
          "#F4A261", // Sandy Brown
          "#2A9D8F"  // Teal
        ],
        borderColor: "none",
        borderWidth: 0,
      },
    ],
  };

  const options = {
    layout: {},
    plugins: {
      legend: {
        position: "bottom" as const,
        labels: {
          usePointStyle: true,
          padding: 20,
          color: "#fff",
        },
      },
    },
  };

  return (
    <div className={styles.container}>
      <Doughnut
        className={styles.doughnut}
        data={chartData}
        options={options}
      ></Doughnut>
    </div>
  );
};

export default DoughnutChart;
