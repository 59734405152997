import Hero from "src/components/Hero";
import Landing from "src/components/Landing";

const Home = () => {
  return (
    <>
      <Hero />
      <Landing />
    </>
  );
};

export default Home;
