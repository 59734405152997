import btc from "../../assets/img/og-pass-logo.webp";
import bitmap from "../../assets/img/bitmap.png";
import frog from "../../assets/img/bitcoin-frog.webp";
import punk from "../../assets/img/bitcoin-punk.webp";
import nodemonke from "../../assets/img/nodemonke.png";
import ordimax from "../../assets/img/ordimax.png";
import quantumCats from "../../assets/img/quantum-cats.webp";

export const nftData = [
  {
    title: "Satoshi pass",
    imageUrl: btc,
  },
  {
    title: "Bitmap",
    imageUrl: bitmap,
  },
  {
    title: "Bitcoin Frog",
    imageUrl: frog,
  },
  {
    title: "Bitcoin Punk",
    imageUrl: punk,
  },
  {
    title: "NodeMonke",
    imageUrl: nodemonke,
  },
  {
    title: "Ordinal Maxi Biz",
    imageUrl: ordimax,
  },
  {
    title: "Quantum Cats",
    imageUrl: quantumCats,
  },
];
