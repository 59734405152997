import roundStarGif from "../../assets/img/round-star.webm";
import sqareStarGif from "../../assets/img/sqare-star.webm";
import star from "../../assets/img/star.webm";
import builders from "../../assets/img/builders.webm";
import whiteCircle from "../../assets/img/white-circle.png";
import investor from "../../assets/img/investors.webp";
import protocols from "../../assets/img/protocols.webm";
import protocolsCircle from "../../assets/img/protocols-circle.webp";
import infrastructure from "../../assets/img/infrastructure.webp";
import community from "../../assets/img/community.webp";

export interface IData {
  title: string;
  image?: { url: string; className: string };
  video?: { url: string; className: string };
  description: string | string[];
  gifs?: {
    url: string;
    className: string;
  }[];
  videos?: {
    url: string;
    className: string;
  }[];
}

export const data = [
  {
    title: "BUILDERS",
    video: {
      url: builders,
      className: "buildersImg",
    },
    description: [
      "Capital Access: Connect with top-tier investors and secure funding with ease. Our platform streamlines your project’s journey from launch to industry leadership.",
      "Strategic Alliances: Engage with a network of seasoned experts and Bitcoin aficionados. Gain insights, mentorship, and the support you need to thrive.",
      "Accelerated Evolution: Leverage cutting-edge tools and resources designed for rapid growth. With OGLONG, you're not just building a project; you're launching a legacy.",
    ],
    gifs: [
      {
        url: whiteCircle,
        className: "buildersGif4",
      },
    ],
    videos: [
      {
        url: roundStarGif,
        className: "buildersGif1",
      },
      {
        url: sqareStarGif,
        className: "buildersGif2",
      },
      {
        url: star,
        className: "buildersGif3",
      },
    ],
  },
  {
    title: "INVESTORS",
    image: {
      url: investor,
      className: "investorsImg",
    },
    description: [
      "Direct Impact: Drive project success with strategic investments. Choose ventures that align with your vision and commit to their growth.",
      "Rewards: Realize substantial gains by investing in our handpicked, high-potential Bitcoin projects.",
      "Empowerment: Join an elite investment community shaping the trajectory of Bitcoin's most promising endeavors. Your capital is the catalyst for innovation.",
    ],
  },
  {
    title: "PROTOCOLS",
    image: {
      url: protocolsCircle,
      className: "protocolsImg",
    },
    description:
      "OGLONG revolutionizes funding with a protocol grounded in efficiency and transparency. We leverage the power of the PSBT protocol, allowing projects to secure funds seamlessly. This means every transaction, every contribution, every step toward your goal is optimized for success.",
    videos: [
      {
        url: protocols,
        className: "protocolsGif1",
      },
    ],
  },
  {
    title: "INFRASTRUCTURE",
    image: {
      url: infrastructure,
      className: "infrastructureImg",
    },
    description:
      "Our commitment at OGLONG goes beyond funding. We're building the infrastructural backbone for developers and users alike, featuring advanced tools for exploration, project tracking, and seamless interaction. Our platform ensures that you have a robust, scalable environment to grow and enhance the Bitcoin ecosystem.",
  },
  {
    title: "COMMUNITY",
    image: {
      url: community,
      className: "communityImg",
    },
    description:
      "Join OGLONG’s community-focused platform, dedicated exclusively to Bitcoin projects. It's where innovation meets a passionate collective, ready to drive change in the crypto space. Engage with projects you believe in, and be part of a movement shaping the future of blockchain, all within the dynamic world of Bitcoin.",
    videos: [
      {
        url: sqareStarGif,
        className: "communityGif1",
      },
      {
        url: sqareStarGif,
        className: "communityGif2",
      },
    ],
  },
];
