import React from "react";

import styles from "./index.module.scss";
import { TableDataItem } from "../ClaimToken";

interface Props {
  data: TableDataItem[];
}

const CustomTable: React.FC<Props> = ({ data }) => {
  return (
    <table className={styles.customTable}>
      <thead>
        <tr className={styles.customTableHeaderRow}>
          <th className={styles.customTableHeaderCell}>Ordinal</th>
          <th className={styles.customTableHeaderCell}>No. of Ordinals</th>
          <th className={styles.customTableHeaderCell}>Claimable OLNG</th>
        </tr>
      </thead>
      <tbody>
        {data.map(
          (item: TableDataItem, index: number) =>
            item.count > 0 && (
              <tr
                key={index}
                className={
                  item.asset.toLowerCase() === "total"
                    ? styles.customTableTotalRow
                    : styles.customTableRow
                }
              >
                <td
                  className={`${styles.customTableCell} ${
                    item.asset.toLowerCase() === "total"
                      ? styles.customTableTotalCell
                      : ""
                  }`}
                >
                  {item.title}
                </td>
                <td
                  className={`${styles.customTableCell} ${
                    item.asset.toLowerCase() === "total"
                      ? styles.customTableTotalCell
                      : ""
                  }`}
                >
                  {item.count}
                </td>
                <td
                  className={`${styles.customTableCell} ${
                    item.asset.toLowerCase() === "total"
                      ? styles.customTableTotalCell
                      : ""
                  }`}
                >
                  {item.claimable}
                </td>
              </tr>
            )
        )}
      </tbody>
    </table>
  );
};

export default CustomTable;
